import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer';
import 'vue-directive-image-previewer/dist/assets/style.css';
import VueCropper from 'vue-cropper';

/*import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';*/
import Vue from 'vue';

import VueClipboard from "vue-clipboard2"
Vue.use(VueClipboard)
let height = screen.height || document.documentElement.clientHeight || document.body.scrollHeight;
let width = screen.width || document.documentElement.clientWidth || document.body.scrollWidth;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueDirectiveImagePreviewer, {
  //1. wrapper背景颜色———— 你也可以写成background: '#000'  //支持rgba、rgb和image: 'url(xxx)'
  background: {
    color: '#000',
  },

  //2. 转换动画
  animate: {
    duration: 300,
    delay: 300,
  },

  //3. 鼠标样式(css)
  cursor: 'pointer',
  previewSize: 20,
  maxWidth: width,
  maxHeight: height,
  zIndex: 1000,
});
Vue.use(VueCropper);

// Vue.use(new VueSocketIO({
//   debug:true,
//   connection:'wss://mfs.eai9.com/taskmanager?cat=1',
// }))
/*Vue.use(VueQuillEditor);*/

Vue.directive('focus', {
  inserted: (el, binding, vnode) => {
    console.log(el, binding, vnode, '..?', vnode.context.$refs.inputRef);
    vnode.context.$nextTick(() => {
      vnode.context.$refs.inputRef.$refs['input'].focus();
    });
  },
});
